// Changed to const to resolve few typescript issues with enums
const UserType = {
  ControlClinic: 'Control',
  ExperimentalClinic: 'Experimental'
} as const

enum UploadedUserType {
  Control = 1,
  Experimental = 2
}

export { UserType, UploadedUserType }
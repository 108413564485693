
import api from '@/services/api'
import { format } from 'date-fns'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { User, UserRequest } from '@/types/User'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import { Group } from '@/types/Groups'
import { DropdownItem, PaginationBody } from '@/types/Misc'
import { UserType } from '@/enums/UserType'
import { useRouter } from 'vue-router'
import { AuditElement } from '@/types/Audit'

export default defineComponent({
  props: ['id'],
  components: {},
  setup(props) {
    const router = useRouter()
    const confirm = useConfirm()
    const toast = useToast()
    const userTypeEnum = UserType
    const userTypeOptions = Object.keys(userTypeEnum).map(key => ({
      label: userTypeEnum[key as keyof typeof userTypeEnum], value: key
    } as DropdownItem))
    
    const userStatus = ref<string>('')

    const user = ref<User>({} as User)
    const groups = ref<Group[]>([])
    const audit = ref<AuditElement[]>([])

    const statusIcon = computed(() => {
      switch (userStatus.value) {
        case 'active':
          return 'check_circle'
        default:
          return 'remove_circle'
      }
    })

    onMounted(() => {
      const body: PaginationBody = {
        pageSize: 10,
        pageNumber: 1,
        searchValue: '',
        orderBy: '',
        descending: true
      }
      api.put('manager/group/filter', body)
        .then(res => {
          groups.value = res?.data.data.list
        })
        .catch((err: any) => toast.add({ severity: 'error', summary: err.message, life: 3000}))

      api.get(`manager/user/${props.id}`)
        .then(res => {
          user.value = res?.data.data
          userStatus.value = user.value.isRegistrationCompleted ? 'active' : 'inactive'
          fetchAudit()
        })
        .catch((err: any) => toast.add({ severity: 'error', summary: err.message, life: 3000}))
    })

    const fetchAudit = () => {
      const body: PaginationBody = {
        pageSize: 3,
        pageNumber: 1,
        searchValue: '',
        orderBy: '',
        descending: true
      }
      api.put(`manager/audit/user/${props.id}?logType=Login`, JSON.stringify(body))
        .then(res => audit.value = res?.data.data.list)
        .catch((err: any) => toast.add({ severity: 'error', summary: err.message, life: 3000}))
    }

    const returnDate = (ts: number): string => {
      return format(new Date(ts * 1000), 'dd-MM-yyyy, H:mm:ss')
    }

    const handleSubmit = (): void => {
      const newUser: UserRequest = {
        externalId: user.value.externalId,
        groupExternalId: user.value.group.externalId,
        userType: user.value.userType,
        isTester: user.value.isTester,
        email: user.value.email,
        name: user.value.name
      } as UserRequest

      api.put('manager/user', JSON.stringify(newUser))
        .then(() => router.push('/users/all'))
        .catch((err: any ) => toast.add({ severity: 'error', summary: err.message, life: 3000}))
    }

    const deleteUser = (id: string) => {
      confirm.require({
        message: 'Are you sure you want to delete selected users?',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          api.delete(`manager/user/${id}`).then(() => {
            router.push('/users/all')
          }).catch((err: any) => toast.add({ severity: 'error', summary: err.message, life: 3000}))
        }
      })
    }

    const resetPassword = () => {
      confirm.require({
        message: 'We will send you an email with link to set a new password. Do you want to continue?',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          api.post(
            `manager/user/resetpassword`,
            JSON.stringify({ externalId: user.value.externalId }))
          .then(() => {
            toast.add({ severity: 'info', summary: 'Email has been sent.', life: 3000 })
          })
          .catch((err: any) => toast.add({ severity: 'error', summary: err.message, life: 3000}))
        }
      })
    }

    const goToLog = () => {
      router.push(`/user-details/${props.id}/audit`)
    }

    return { user, audit, handleSubmit, deleteUser, resetPassword, returnDate, goToLog, userStatus, statusIcon, groups, userTypeOptions }
  }
})


import api from '@/services/api'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import { Moderator } from '@/types/Moderator'
import { PaginationBody } from '@/types/Misc'

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter()
    const toast = useToast()
    const confirm = useConfirm()

    const moderators = ref<Moderator[]>([])

    onMounted(() => {
      const body: PaginationBody = {
        pageSize: 10,
        pageNumber: 1,
        searchValue: '',
        orderBy: '',
        descending: true
      }
      api.put('admin/user/filter', JSON.stringify(body))
        .then(res => {
          moderators.value = res?.data.data.list
        }).catch((err: any) => toast.add({ severity: 'error', summary: err.message, life: 3000}))
    })

    const tableUtilsVisible = computed(() => {
      return moderators.value.filter(mod => mod.selected).length
    })
    const areUsersSelected = computed(() => {
      return tableUtilsVisible.value ? true : false
    })

    const addModerator = () => {
      router.push('/add-moderator')
    }

    const deleteUsers = () => {
      confirm.require({
        message: 'Are you sure you want to delete selected users?',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          toast.add({ severity: 'info', summary: 'Users deleted', detail: 'Selected users deleted', life: 3000 })
        },
        reject: () => {
          toast.add({ severity: 'info', summary: 'Action cancelled', life: 3000})
        }
      })
    }

    return {
      moderators,
      tableUtilsVisible,
      areUsersSelected,
      addModerator,
      deleteUsers
    }
  },
})

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import UsersView from '../views/UsersView.vue'
import AddUserView from '../views/AddUserView.vue'
import UserDetailsView from '../views/UserDetailsView.vue'
import ModeratorsView from '../views/ModeratorsView.vue'
import AddModeratorView from '../views/AddModeratorView.vue'
import ModeratorDetailsView from '../views/ModeratorDetailsView.vue'
import GroupsView from '../views/GroupsView.vue'
import LoginView from '../views/LoginView.vue'
import AuditView from '../views/AuditView.vue'
import AddGroupView from '../views/AddGroupView.vue'
import ForgotResetPasswordView from '../views/ForgotResetPasswordView.vue'
import GroupDetailsView from '../views/GroupDetailsView.vue'
import ToolsView from '../views/Tools/ToolsView.vue'
import ToolsProcessView from '../views/Tools/ToolsProcessView.vue'
import ResendPasswordView from '../views/ResendPasswordView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/users/:group',
    name: 'UsersView',
    component: UsersView,
    props: true
  },
  {
    path: '/add-user/:group',
    name: 'AddUserView',
    component: AddUserView,
    props: true
  },
  {
    path: '/user-details/:id',
    name: 'UserDetailsView',
    component: UserDetailsView,
    props: true
  },
  {
    path: '/user-details/:id/audit',
    name: 'AuditView',
    component: AuditView,
    props: true
  },
  {
    path: '/moderators',
    name: 'ModeratorsView',
    component: ModeratorsView
  },
  {
    path: '/add-moderator',
    name: 'AddModeratorView',
    component: AddModeratorView
  },
  {
    path: '/moderator-details/:id',
    name: 'ModeratorDetailsView',
    component: ModeratorDetailsView,
    props: true
  },
  {
    path: '/groups',
    name: 'GroupsView',
    component: GroupsView
  },
  {
    path: '/add-group',
    name: 'AddGroupView',
    component: AddGroupView
  },
  {
    path: '/group-details/:id',
    name: 'GroupDetailsView',
    component: GroupDetailsView,
    props: true
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/identity/forgot-reset-password',
    name: 'ForgotResetPasswordView',
    component: ForgotResetPasswordView
  },
  {
    path: '/tools',
    name: 'ToolsView',
    component: ToolsView
  },
  {
    path: '/tools/process',
    name: 'ToolsProcessView',
    component: ToolsProcessView
  },
  {
    path: '/resend-password',
    name: 'ResendPasswordView',
    component: ResendPasswordView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/identity/forgot-reset-password', '/resend-password']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')
  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router


import api from '@/services/api'
import { useToast } from 'primevue/usetoast'
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {},
  setup() {
    const toast = useToast()
    const router = useRouter()
    const email = ref<string>('')
    const emailSent = ref<boolean>(false)

    const sendEmail = () => {
      api.get(`/user/identity/resetpassword/${email.value}`)
      .then(() => {
        emailSent.value = true
        toast.add({ severity: 'info', summary: 'E-mail sent!', life: 3000})
      }).catch((err: any) => console.log(err.message))
    }

    const goToLogin = () => {
      router.push('/login')
    }

    return { 
      email,
      emailSent,
      sendEmail,
      goToLogin
    }
  },
})

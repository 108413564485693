
import api from '@/services/api'
import { defineComponent, ref } from 'vue'
import { ModeratorRequest } from '@/types/Moderator'
import { useRouter } from 'vue-router'
import { User } from '@/types/User'

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter()
    
    const moderator = ref<ModeratorRequest>({} as ModeratorRequest)

    const user = ref({} as User)

    // TODO: different names for user profile and user token info to avoid confusion
    api.get('user/profile')
      .then(res => user.value = res?.data.data)
      .catch((err: any) => console.log(err.message))

    const handleSubmit = (): void => {
      const newModerator: ModeratorRequest = { 
        ...moderator.value,
      }
      api.post('admin/user/manager', JSON.stringify(newModerator))
      .then(() => {
        router.push('/moderators')
      }).catch((err: any) => console.log(err.message))
    }

    return { moderator, handleSubmit }
  }
})


import api from '@/services/api'
import { format } from 'date-fns'
import { AuditElement } from '@/types/Audit';
import { computed, defineComponent, onMounted, ref } from "@vue/runtime-core";
import { PaginationBody } from '@/types/Misc';

export default defineComponent({
  props: ['id'],
  setup(props) {

    const tableBody = ref<PaginationBody>({
      pageSize: 50,
      pageNumber: 1,
      searchValue: '',
      orderBy: '',
      descending: true
    })

    const audit = ref<AuditElement[]>([])
    const auditCount = ref<number>(0)
    const currentUser = ref<string>('')
    let searchTimeout = ref<number | null>(0)
    const minCount = computed<number>(() => {
      return tableBody.value.pageNumber * tableBody.value.pageSize - (tableBody.value.pageSize - 1)
    })
    const maxCount = computed<number>(() => {
      return tableBody.value.pageNumber * tableBody.value.pageSize
    })

    onMounted(() => {
      fetchAudit()
    })

    const fetchAudit = () => {
      api.put(`manager/audit/user/${props.id}`, JSON.stringify(tableBody.value))
        .then(res => {
          audit.value = res?.data.data.list
          currentUser.value = res?.data.data.list?.[0]?.user.name
          auditCount.value = res?.data.data.pageInfo.totalCount
        }).catch((err: any) => console.log(err.message))
    }

    const returnDate = (ts: number): string => {
      return format(new Date(ts * 1000), 'dd-MM-yyyy, H:mm:ss')
    }

    const actionBy = (item: AuditElement): string => {
      return item.manager ? `Manager (${item.manager.name})` : 'User'
    }

    const nextPage = () => {
      if (!(tableBody.value.pageSize * tableBody.value.pageNumber >= auditCount.value)) {
        tableBody.value.pageNumber += 1
        fetchAudit()
      }
    }

    const prevPage = () => {
      if(tableBody.value.pageNumber > 1) {
        tableBody.value.pageNumber -= 1
        fetchAudit()
      }
    }

    const searchAudit = () => {
      if (searchTimeout.value) {
        clearTimeout(searchTimeout.value)
        searchTimeout.value = null
      }
      searchTimeout.value = window.setTimeout(() => {
        const searchLength = tableBody.value.searchValue.length
        if (searchLength >= 3 || searchLength === 0) {
          tableBody.value.pageNumber = 1
          fetchAudit()
        }
      },800)
    }

    const paginChange = (payload: PaginationBody) => {
      tableBody.value = { ...payload }
      fetchAudit()
    }

    return {
      props,
      audit,
      currentUser,
      minCount,
      maxCount,
      auditCount,
      tableBody,
      returnDate,
      actionBy,
      nextPage,
      prevPage,
      paginChange,
      searchAudit
    }
  }
})


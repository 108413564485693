import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import setupInterceptors from './services/setupInterceptors'

// external services
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'

// external modules
import PrimeVue from 'primevue/config'
import InputSwitch from 'primevue/inputswitch'
import Toast from 'primevue/toast'
import TabMenu from 'primevue/tabmenu'
import Dropdown from 'primevue/dropdown'
import Checkbox from 'primevue/checkbox'
import ConfirmDialog from 'primevue/confirmdialog'
import FileUpload from 'primevue/fileupload'
import ColorPicker from 'primevue/colorpicker'
import Avatar from 'primevue/avatar'
import Menu from 'primevue/menu'
import ProgressSpinner from 'primevue/progressspinner';

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

import PaginationComponent from './components/PaginationComponent.vue'

// global styles
import './assets/reset.css'
import './assets/primeCustomStyles.scss'
import './assets/main.scss'

setupInterceptors(store)

createApp(App)
  .use(store)
  .use(router)
  .use(PrimeVue)
  .use(ToastService)
  .use(ConfirmationService)
  .component('Toast', Toast)
  .component('Switch', InputSwitch)
  .component('TabMenu', TabMenu)
  .component('Dropdown', Dropdown)
  .component('Checkbox', Checkbox)
  .component('ConfirmDialog', ConfirmDialog)
  .component('FileUpload', FileUpload)
  .component('ColorPicker', ColorPicker)
  .component('Avatar', Avatar)
  .component('Menu', Menu)
  .component('ProgressSpinner', ProgressSpinner)
  .component('Pagination', PaginationComponent)
  .mount('#app')

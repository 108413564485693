
import api from '@/services/api'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import { User, UserRequest } from '@/types/User'
import { UserType } from '@/enums/UserType'
import { PaginationBody, FiltrationItem, entries } from '@/types/Misc'

export default defineComponent({
  props: ['group'],
  components: {},
  setup(props) {
    const router = useRouter()
    const toast = useToast()
    const confirm = useConfirm()

    const userTypeEnum = UserType

    const tableBody = ref<PaginationBody>({
      pageSize: 50,
      pageNumber: 1,
      searchValue: '',
      orderBy: '',
      descending: true
    })

    const paginOptions = [
      {name: 50, value: 50},
      {name: 100, value: 100},
      {name: 150, value: 150},
    ]

    const users = ref<User[]>([])

    onMounted(() => {
      fetchGroups()
    })

    watch(() => props.group, () => {
      tableBody.value.pageNumber = 1
      fetchGroups()
    })

    const userCount = ref<number>(0)
    const groupName = ref<string>('')
    let searchTimeout = ref<number | null>(0)

    type options = "email" | "name" | "userType"
    const filters = ref<FiltrationItem<options>> ({
      email: {
        config: {
          descending: false,
          enabled: false
        }
      },
      name: {
        config: {
          descending: false,
          enabled: false
        }
      }, 
      userType: {
        config: {
          descending: false,
          enabled: false
        }
      }
    })

    const minCount = computed<number>(() => {
      return tableBody.value.pageNumber * tableBody.value.pageSize - (tableBody.value.pageSize - 1)
    })
    const maxCount = computed<number>(() => {
      return tableBody.value.pageNumber * tableBody.value.pageSize
    })
    const tableUtilsVisible = computed<number>(() => {
      return users.value.filter(user => user.selected).length
    })
    const areUsersSelected = computed<boolean>(() => {
      return tableUtilsVisible.value ? true : false
    })

    const fetchGroups = () => {
      if (props.group !== 'all') {
        try {
          api.put(`manager/user/filter/${props.group}`, JSON.stringify(tableBody.value)).then(res => {
            users.value = res?.data.data.list
          })
        } catch (err: any) {
          console.log(err.message)
        }
        try {
          api.get(`manager/group/${props.group}`).then(res => {
            userCount.value = res?.data.data.usersCount
            groupName.value = res?.data.data.name
          })
        } catch (err: any) {
          console.log(err.message)
        }
      } else {
        try {
          api.put('manager/user/filter', JSON.stringify(tableBody.value)).then(res => {
            groupName.value = ''
            users.value = res?.data.data.list
            userCount.value = res?.data.data.pageInfo.totalCount
          })
        } catch (err: any) {
          console.log(err.message)
        }
      }
    }

    const toggleAllItems = () => {
      if (areUsersSelected.value) {
        users.value.forEach(item => item.selected = false)
      } else {
        users.value.forEach(item => item.selected = true)
      }
    }

    const addUser = () => {
      const redirectProp = props.group !== 'all' ? props.group : 'all'
      router.push({name: 'AddUserView', params: {group: redirectProp} })
    }

    const deleteUsers = () => {
      confirm.require({
        message: 'Are you sure you want to delete selected users?',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const idsToDelete = users.value.reduce((a, o) => (o.selected && a.push(o.externalId), a), [] as string[])
          await api.post('manager/user/deletemany', JSON.stringify({externalIds: idsToDelete}))
            .then(() => {
              fetchGroups()
              toast.add({ severity: 'info', summary: 'Users deleted', detail: 'Selected users deleted', life: 3000 })
            })
            .catch((err: any) => toast.add({ severity: 'error', summary: err.message, life: 3000}))
        },
        reject: () => {
          toast.add({ severity: 'info', summary: 'Action cancelled', life: 3000})
        }
      })
    }

    const updateUser = (user: User) => {
      const newUser: UserRequest = {
        externalId: user.externalId,
        groupExternalId: user.group.externalId,
        userType: user.userType,
        isTester: user.isTester,
        email: user.email,
        name: user.name
      } as UserRequest

      api.put('manager/user', JSON.stringify(newUser))
        .then(() => toast.add({ severity: 'info', summary: 'Users updated', detail: 'Selected user\'s debug option updated', life: 3000 }))
        .catch((err: any) => toast.add({ severity: 'error', summary: err.message, life: 3000}))
    }

    const exportUsers = () => {
      toast.add({ severity: 'info', summary: 'Feature coming soon!', life: 3000})
    }

    const exportUserResults = () => {
      toast.add({ severity: 'info', summary: 'Feature coming soon!', life: 3000})
    }

    const setDebugAll = () => {
      const idsToSet = users.value.reduce((a, o) => (o.selected && a.push(o.externalId), a), [] as string[])
      const body = {
        value: true,
        externalIds: idsToSet
      }
      api.put('manager/user/settester', JSON.stringify(body))
        .then(() => {
          toast.add({ severity: 'info', summary: 'Users updated', detail: 'Selected user\'s debug option updated', life: 3000 })
          fetchGroups()
        })
        .catch((err: any) => toast.add({ severity: 'error', summary: err.message, life: 3000}))
    }

    const searchUsers = () => {
      if (searchTimeout.value) {
        clearTimeout(searchTimeout.value)
        searchTimeout.value = null
      }
      searchTimeout.value = window.setTimeout(() => {
        const searchLength = tableBody.value.searchValue.length
        if (searchLength >= 3 || searchLength === 0) {
          tableBody.value.pageNumber = 1
          fetchGroups()
        }
      },800)
    }

    const nextPage = () => {
      if (!(tableBody.value.pageSize * tableBody.value.pageNumber >= userCount.value)) {
        tableBody.value.pageNumber += 1
        fetchGroups()
      }
    }

    const prevPage = () => {
      if(tableBody.value.pageNumber > 1) {
        tableBody.value.pageNumber -= 1
        fetchGroups()
      }
    }

    const changeFilters = (filterName: options): void => {
      const filter = filters.value[filterName]

      entries(filters.value)
        .filter(([key]) => key != filterName)
        .forEach(([, value]) => value.config.enabled = false)

      if (!filter.config.enabled) {
        filter.config.enabled = true
      } else {
        filter.config.descending = !filter.config.descending
      }

      tableBody.value.descending = filter.config.descending
      tableBody.value.orderBy = filterName
      tableBody.value.pageNumber = 1
      fetchGroups()
    }

    const paginChange = (payload: PaginationBody) => {
      tableBody.value = { ...payload }
      onPaginationUpdate()
    }

    const onPaginationUpdate = () => {
      fetchGroups()
    }

    return {
      users,
      tableBody,
      userCount,
      tableUtilsVisible,
      areUsersSelected,
      UserType,
      userTypeEnum,
      minCount,
      maxCount,
      groupName,
      filters,
      paginOptions,
      addUser,
      deleteUsers,
      exportUsers,
      exportUserResults,
      searchUsers,
      nextPage,
      prevPage,
      toggleAllItems,
      setDebugAll,
      updateUser,
      changeFilters,
      paginChange,
      onPaginationUpdate
    }
  },
})

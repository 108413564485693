import axios from 'axios'
import TokenService from './token.service'

class AuthService {
  login(userData: any) {
    const authUri = process.env.VUE_APP_AUTH_URI
    return axios.post(
        `${authUri}identity/login`,
        userData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )
      .then(res => {
        if (res.data.access_token) {
          TokenService.setUserTokenData(res.data)
        }
        return res.data
      })
  }
  logout() {
    TokenService.removeUserTokenData()
  }
}
export default new AuthService();
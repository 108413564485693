
import api from '@/services/api'
import { defineComponent, onMounted, ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
import { UserRequest, UserUploadEnv, CreateUserResponse } from '@/types/User'
import { Group } from '@/types/Groups'
import { DropdownItem, PaginationBody } from '@/types/Misc'
import { UserType, UploadedUserType } from '@/enums/UserType'

export default defineComponent({
  props: ['group'],
  components: {},
  setup(props) {
    const router = useRouter()
    const toast = useToast()
    const userTypeEnum = UserType
    const uploadedUserTypeEnum = UploadedUserType
    const userTypeOptions = Object.keys(userTypeEnum).map(key => ({
      label: userTypeEnum[key as keyof typeof userTypeEnum], value: key
    } as DropdownItem))

    const user = ref<UserRequest>({} as UserRequest)
    const groups = ref<Group[]>([])
    const selectedGroup = ref<Group>({} as Group)
    const activeTab = ref<number>(0)
    const tableUsers = ref<CreateUserResponse[]>([])
    const errorMsg = ref<string>('')
    const codeErrorMsg = ref<string>('')
    const isUploading = ref(false)
    const progressPercentage = ref<number>(0)
    let checkInterval: any = null

    const tabMenu = [
      { label: 'Type Informations' },
      { label: 'Import from CSV' }
    ]

    onMounted(() => {
      const body: PaginationBody = {
        pageSize: 999,
        pageNumber: 1,
        searchValue: '',
        orderBy: '',
        descending: true
      }
      api.put('manager/group/filter', body)
        .then(res => {
          groups.value = res?.data.data.list
          const defaultGroup = props.group !== 'all' ? groups.value.filter(item => item.externalId === props.group)[0].name : 'Default'
          selectedGroup.value = groups.value.filter(item => item.name === defaultGroup)[0]
        }).catch((err: any) => toast.add({ severity: 'error', summary: err.message, life: 3000}))
    })

    const handleSubmit = async () => {
      checkLoginCode()
      if (!codeErrorMsg.value.length) {
        const newUser: UserRequest = { ...user.value, groupExternalId: selectedGroup.value.externalId }

        api.post('manager/user', JSON.stringify(newUser))
          .then(() => {
            router.push('/users/all')
          }).catch((err: any) => toast.add({ severity: 'error', summary: err.message, life: 3000}))
      }
    }

    const checkLoginCode = () => {
      codeErrorMsg.value = ''
      if (user.value.code) {
        const regex = /^[a-zA-Z0-9]*$/
        codeErrorMsg.value = !regex.test(user.value.code) ? 'Code should contain only numbers and letters' : ''
      }
    }

    const onTabChange = (_event: any) => {
      activeTab.value = _event.index
    }

    const onUpload = async (event: any) => {
      isUploading.value = true
      const formData = new FormData()
      formData.append("file", event.files[0])
      let sessionId = ''
      await api.post('manager/user/job/upload', formData)
        .then(res => {
          sessionId = res.data.data
          checkInterval = setInterval(() => checkProgress(sessionId), 1000)
        })
        .catch((err) => {
          errorMsg.value = err.response.data.Errors[0]?.Message
          progressPercentage.value = 0
          isUploading.value = false
        })
    }

    const checkProgress = (sessionId: string) => {
      api.get(`manager/usersync/${sessionId}`)
        .then(res => {
          const data: UserUploadEnv = res?.data
          progressPercentage.value = (data?.data.current / data?.data.count) * 100
          tableUsers.value = data.data.results
          if (res?.data.data?.isFinished) {
            isUploading.value = false
            progressPercentage.value = 0
            clearInterval(checkInterval)
            // sorting incorrect entries first
            tableUsers.value.sort((a, b) => a === b ? -1 : 1)
          }
        })
        .catch((err: any) => console.log(err.message))
    }

    return { 
      user,
      tabMenu,
      groups,
      activeTab,
      tableUsers,
      selectedGroup,
      uploadedUserTypeEnum,
      userTypeEnum,
      userTypeOptions,
      errorMsg,
      codeErrorMsg,
      isUploading,
      progressPercentage,
      handleSubmit,
      onTabChange,
      onUpload }
  }
})

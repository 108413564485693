import { createStore } from 'vuex'

import AuthService from '@/services/auth.service'
import TokenService from '@/services/token.service'
import { decodeToken } from 'jsontokens'
import api from '@/services/api'
import { PaginationBody } from '@/types/Misc'
import { Claim } from '@/types/Claims'
import { Group } from '@/types/Groups'

const user: any = TokenService.getUserTokenData()

const store = createStore({
  state: {
    user: user,
    userType: '' as string,
    status: {
      loggedIn: !!user as boolean
    },
    groupList: [] as Group[]
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null
    },
    refreshUser(state, userData) {
      state.status.loggedIn = true
      state.user = { ...userData }
    },
    setUserRole(state, role) {
      state.userType = role
    },
    setGroupList(state, groupList) {
      state.groupList = groupList
    }
  },
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      );
    },
    logout({ commit }) {
      AuthService.logout()
      commit('logout')
    },
    refreshUser({ commit }, userData) {
      commit('refreshUser', userData)
    },
    fetchGroups({ commit }) {
      const body: PaginationBody = {
        pageSize: 100,
        pageNumber: 1,
        searchValue: '',
        orderBy: '',
        descending: true
      }
      api.put('manager/group/filter', JSON.stringify(body)).then(res => {
        commit('setGroupList', res?.data.data.list)
      }).catch((err: any) => console.log(err.message))
    },
    setUserRole({ commit }) {
      const token = TokenService.getLocalAccessToken()
      if (token) {
        const claims: Claim = decodeToken(token) as Claim
        if (typeof(claims?.payload.role) == 'string') {
          commit('setUserRole', claims?.payload.role)
        } else {
          commit('setUserRole', claims?.payload.role[0])
        }
      }
    }
  }
})

export default store

import api from '@/services/api'
import { defineComponent, ref } from 'vue'
import { GroupRequest } from '@/types/Groups'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter()
    const store = useStore()
    const newGroup = ref<GroupRequest>({color: 'ff0000'} as GroupRequest)

    const handleSubmit = async () => {
      api.post('manager/group', JSON.stringify(newGroup.value))
      .then(() => {
        store.dispatch('fetchGroups')
        router.push('/groups')
      }).catch((err: any) => {
        console.log(err.message)
      })
    }

    return { 
      handleSubmit, 
      newGroup 
    }
  }
})

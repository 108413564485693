
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
  components: {},
  setup() {
    const app = 'app';

    return { app }
  }
});

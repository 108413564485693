
import { defineComponent, ref } from 'vue'
import { useRouter, RouterLink } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    RouterLink
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    const username = ref<string>('')
    const password = ref<string>('')
    const formErr = ref<string>('')

    const confirmCred = async () => {
      const data: URLSearchParams = new URLSearchParams({
        username: username.value,
        grant_type: 'password',
        password: password.value,
        scope: 'api1 offline_access'
      })
      try {
        await store.dispatch('login', data)
        router.push('/users/all')
      } catch (err: any) {
        formErr.value = err.response.data ? err.response.data : 'Wrong username and/or password.'
      }
    }

    return {
      username,
      password,
      formErr,
      confirmCred
    }
  },
})

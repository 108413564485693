
import { UserRole } from '@/enums/UserRole'
import { Group } from '@/types/Groups'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  components: {},
  setup() {
    const store = useStore()

    const groupsMenu = computed(() => {
      return store.state.groupList.map((group: Group) => {
        return({
          name: group.name,
          to: `/users/${group.externalId}`,
          visible: true
        })
      })
    })

    const showModLink = computed<boolean>(() => {
      return store.state.userType === UserRole.Administrator
    })

    const showSubmenu = ref<boolean>(false)

    const menu = computed(() => [
      {
        name: 'Users',
        to: '/users/all',
        visible: true,
      },
      {
        name: 'Groups',
        to: '/groups',
        visible: true,
        menuOpen: showSubmenu.value,
        children: groupsMenu.value
      },
      {
        name: 'Moderators',
        to: '/moderators',
        visible: showModLink.value,
      },
      {
        name: 'Tools',
        to: '/tools',
        visible: true
      },
    ])

    onMounted(() => {
      store.dispatch('fetchGroups')
      store.dispatch('setUserRole')
    })
    
    const toggleMenu = (item: any) => {
      showSubmenu.value = !showSubmenu.value
    }

    return { menu, toggleMenu }
  },
})

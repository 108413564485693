
import api from '@/services/api'
import { defineComponent, onMounted, ref } from 'vue'
import { Group, GroupRequest } from '@/types/Groups'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: ['id'],
  components: {},
  setup(props) {
    const router = useRouter()
    
    const userStatus = ref<string>('active')

    const group = ref<Group>({} as Group)
    const groups = ref<Group[]>([])

    onMounted(() => {
      api.get(`manager/group/${props.id}`)
      .then(res => {
        group.value = res?.data.data
      }).catch((err: any) => console.log(err.message))
    })

    const handleSubmit = () => {
      const groupUpdate: GroupRequest = {
        externalId: group.value.externalId,
        color: group.value.color
      };
      api.put('manager/group', JSON.stringify(groupUpdate))
        .then(() => router.push('/groups'))
        .catch((err: any) => console.log(err.message))
    }


    return { 
      group,
      userStatus,
      groups,
      handleSubmit
    }
  }
})

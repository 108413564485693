import axios from "axios"

const baseUri = process.env.VUE_APP_BASE_URI

const instance = axios.create({
  baseURL: baseUri,
  headers: {
    'Content-Type': 'application/json',
  },
})

export default instance
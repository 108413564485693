
import api from '@/services/api'
import format from 'date-fns/format'
import { JobItem } from '@/types/Tools'
import { AxiosResponse } from 'axios'
import { defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
  components: {},
  setup() {
    const results = ref<JobItem[]>([{} as JobItem])
    let checkInterval: any = null

    onMounted(() => {
      fetchResults()
    })

    const fetchResults = () => {
      api.get('manager/usersync/all').then((res: AxiosResponse) => {
        results.value = res.data.data
        results.value.forEach((item: JobItem) => {
          item.completion = Math.round(item.current / item.count * 10000) / 100
        })
        if (results.value.filter(item => !item.isFinished).length === 0) {
          clearInterval(checkInterval)
        } else if (!checkInterval) {
          checkInterval = setInterval(() => fetchResults(), 2500)
        }
      })
    }

    const returnDate = (ts: number): string => {
      return ts ? format(new Date(ts * 1000), 'dd-MM-yyyy, H:mm:ss') : '-'
    }

    return {
      results,
      returnDate
    }
  },
})
